@import "variables";
@import "mixins/breakpoints";
@import "mixins/containers";

.tiled-carousel {
  padding-top: 24px;

  .glide__slide {
    height: auto;
  }

  .glide__bullets {
    position: static;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    display: flex;
    padding: 2em;
    align-items: center;
    justify-content: center;
    transform: none;
  }

  .glide__bullet--button {
    padding: 12px 6px;

    &:hover {
      .glide__bullet {
        background-color: $gray-600;
        outline-color: $gray-600;
        outline-offset: 3px;
      }
    }

    &.glide__bullet--active {
      .glide__bullet {
        background-color: $gray-600;
        outline-color: $gray-600;
        outline-offset: 3px;
      }
    }
  }

  .glide__bullet {
    background-color: $gray-600;
    border: none;
    outline: 4px solid transparent;
    outline-offset: 0px;
    transition: outline-color 200ms ease-in-out 0s, outline-offset 200ms ease-in-out 0s;
  }
}

.tiled-carousel__tile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.tiled-carousel__tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;
  border-radius: 2px;
  padding: 16px;
  height: 100%;

  @include media-breakpoint-up(md) {
    padding: 24px;
  }

  a {
    text-decoration: none;
    color: $gray-900;
    font-size: 1.8rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;
    width: 100%;
    height: 100%;
    transition: color 200ms ease-in-out;

    @include media-breakpoint-up(md) {
      gap: 28px;
    }

    &:hover {
      text-decoration: none;
      color: $gray-700;
    }
  }

  .tiled-carousel__tile-grid & {
    &.full-width {
      grid-column: 1 / span 2;
    }
  }
}

.tiled-carousel__tile-image-wrapper {
  display: flex;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}
